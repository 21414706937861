import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  z-index: 6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: black;

  width: 100%;

  @media (max-width: 48em) {
    svg {
      width: 20vw;
    }
  }

  svg {
    width: 10vw;

    height: auto;
    overflow: visible;
    stroke-linejoin: round;
    stroke-linecap: round;
    g {
      path {
        stroke: #fff;
      }
    }
  }
`;

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,

    transition: {
      duration: 2,
      // yoyo: Infinity,
      ease: "easeInOut",
    },
  },
};
const textVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      duration: 1,
      yoyo: Infinity,

      ease: "easeInOut",
    },
  },
};

const Text = styled(motion.span)`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.text};
  padding-top: 0.5rem;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const Loader = () => {
  return (
    <Container
      initial={{ y: 0, opacity: 1 }}
      exit={{ y: "100%", opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="512"
        height="512"
        x="0"
        y="0"
        viewBox="0 0 512 512"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve"
        className=""
      >
        <g>
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="M256 98.294c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15s-15 6.716-15 15v68.294c0 8.284 6.716 15 15 15zM497 241h-68.294c-8.284 0-15 6.716-15 15s6.716 15 15 15H497c8.284 0 15-6.716 15-15s-6.716-15-15-15zM98.294 256c0-8.284-6.716-15-15-15H15c-8.284 0-15 6.716-15 15s6.716 15 15 15h68.294c8.284 0 15-6.716 15-15zM395.924 131.91c3.838 0 7.678-1.465 10.606-4.394l36.853-36.852c5.858-5.857 5.858-15.355 0-21.213-5.856-5.858-15.354-5.858-21.213 0l-36.853 36.852c-5.858 5.857-5.858 15.355 0 21.213a14.954 14.954 0 0 0 10.607 4.394zM105.469 127.516c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394c5.858-5.858 5.858-15.355 0-21.213L89.829 69.451c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213z"
            fill="#ffffff"
            opacity="1"
            data-original="#000000"
            className=""
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="M441.134 306.049h-65.698A129.405 129.405 0 0 0 385.499 256c0-71.406-58.093-129.5-129.499-129.5S126.5 184.594 126.5 256c0 17.259 3.447 34.279 10.052 50.049H70.866c-8.284 0-15 6.716-15 15s6.716 15 15 15h370.268c8.284 0 15-6.716 15-15s-6.716-15-15-15zM156.5 256c0-54.864 44.636-99.5 99.5-99.5s99.499 44.635 99.499 99.5c0 17.658-4.667 34.9-13.504 50.049H170.001C161.172 290.917 156.5 273.626 156.5 256zM350.229 364.614H161.77c-8.284 0-15 6.716-15 15s6.716 15 15 15h188.46c8.284 0 15-6.716 15-15s-6.716-15-15.001-15z"
            fill="#ffffff"
            opacity="1"
            data-original="#000000"
            className=""
          />
        </g>
      </svg>
      <Text variants={textVariants} initial="hidden" animate="visible">
        My Studio
      </Text>
    </Container>
  );
};

export default Loader;
